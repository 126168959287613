<template lang="pug">
el-drawer(
  :title="formTitle"
  size="80%"
  :visible.sync="drawer"
  modal
  append-to-body
  :before-close="handleClose"
  @open="handleDrawerOpen")
  Detail(
    v-if="formSubType === 'info' && drawer"
    :data="formData"
    :groupSplit="groupSplit"
    :groupTitle="groupTitle"
    :formFields="readonlyFields"
    :columns="columns")
  DataformGroup(
    v-if="formSubType !== 'info' && showForm"
    ref="form"
    v-model="formData"
    groupType="row"
    buttonFixed
    :colspanNum="3"
    :groupSplit="groupSplit"
    :groupTitle="groupTitle"
    :formFields="formFields"
    :buttonList="buttonList"
    @onChange="handleFormChange")
  el-dialog.dialog-box(
    v-if="showBillList"
    title="存在欠费"
    append-to-body
    width="50%"
    :visible.sync="showBillList"
    :before-close="handleDialogClose")
    .form-box
      span.tip.text-danger 注意：证件【{{ dialogTip }}】存在欠费，请确认是否继续建档
      DataTable.table-box(
        ref="table"
        :showFilter="false"
        :hasPage="false"
        :data="billList"
        :columns="billColumns"
        :settingButtonList="[]")
    ButtonGroup(
      slot="footer"
      :buttonList="dialogButtonList")
</template>

<script>
import { mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { groupTitle, groupSplit, editableFields, readonlyFields, getIdNumRule, getIdAttchRule } from '../config'
import { tableColumns } from '@/views/FileManage/UsewaterManage/config.js'
import Detail from './DetailTab.vue'

export default {
  name: 'DrawerForm',
  components: { Detail },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => ({})
    },
    formSubType: {
      type: String,
      default: 'add'
    }
  },
  computed: {
    formTitle () {
      switch (this.formSubType) {
        case 'add':
          return '新增'
        case 'edit':
          return '修改'
        default:
          return `${this.value.customerCode}-${this.value.customerName}`
      }
    },
    formData: {
      get  () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data () {
    return {
      showForm: false,
      // 表单配置
      groupTitle,
      groupSplit,
      readonlyFields,
      formFields: cloneDeep(editableFields),
      buttonList: [{
        label: '提交',
        type: 'primary',
        validate: true,
        func: (form) => {
          const field = this.formFields.find(item => item.name === 'orgId')
          if (field) {
            const item = field.form.options.find(o => o.value === this.formData.orgId)
            this.formData.orgName = item ? item.label : ''
          }
          switch (this.formSubType) {
            case 'add':
              return this.handleAdd(form)
            case 'edit':
              return this.handleEdit(form)
            default:
              break
          }
        }
      }, {
        label: '重置',
        func: () => {
          this.resetForm()
        }
      }],
      // 表单数据
      initFormData: {},
      columns: tableColumns.filter(item => item.name !== 'customerName'),
      // 弹窗配置
      idTypeOptions: [],
      showBillList: false,
      billList: [],
      confirmData: {},
      dialogButtonList: [{
        name: 'confirm',
        label: '继续',
        type: 'primary',
        func: () => {
          return new Promise((resolve, reject) => {
            this.addApi(this.confirmData)
              .then(resolve)
              .catch(reject)
          })
        }
      }, {
        name: 'close',
        label: '关闭',
        func: this.handleDialogClose
      }],
      billColumns: [
        { name: 'billMonth', label: '账期', minWidth: 80 },
        { name: 'billNo', label: '账单编号', minWidth: 130 },
        { name: 'usewaterCode', label: '用水户号', minWidth: 100 },
        {
          name: 'customerName',
          label: '用户',
          width: 160,
          render: (value, field, data) => {
            const { customerName, customerCode } = data
            return `${customerName}(${customerCode})`
          }
        },
        { name: 'quantity', label: '水量' },
        { name: 'totalAmount', label: '总金额', minWidth: 100 },
        { name: 'waterAmount', label: '水费总额', minWidth: 100 },
        { name: 'sewageAmount', label: '污水费总额', minWidth: 100 },
        { name: 'waterResourceAmount', label: '水资源费总额', minWidth: 100 },
        { name: 'penaltyAmount', label: '滞纳金总额', minWidth: 100 },
        { name: 'penaltyDays', label: '滞纳天数' },
        { name: 'billCreateTime', label: '账单产生日期', minWidth: 120, render: { type: 'date' } }
      ]
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    renderRelation () {
      this.getRelations(['idType', 'usewaterStatus', 'usenature', 'waterWorks'])
        .then(res => {
          this.idTypeOptions = res.idType
          this.$renderRelationColumns(res, [this.formFields, this.readonlyFields, this.columns])
          const index = this.formFields.findIndex(field => field.name === 'orgId')
          this.formFields[index].options = res.waterWorks
        })
    },
    // 根据证件类型更新表单规则
    updateFormRule ({ idType }) {
      const idNumIndex = this.formFields.findIndex(field => field.name === 'idNum')
      const idAttchIndex = this.formFields.findIndex(field => field.name === 'idAttchIds')
      this.formFields[idNumIndex].form.rules = getIdNumRule(idType)
      this.formFields[idAttchIndex].form.rules = getIdAttchRule(idType)
      this.$nextTick(() => {
        const formRef = this.$refs.form
        formRef && formRef.$refs.dataformGroup.clearValidate()
      })
    },
    handleDrawerOpen () {
      const index = this.formFields.findIndex(field => field.name === 'orgId')
      this.formFields[index].form.tag = this.formSubType === 'add' ? 'select' : 'text'
      this.initFormData = cloneDeep(this.value)
      this.updateFormRule(this.formData)
      this.showForm = true
      this.$nextTick(() => {
        const formRef = this.$refs.form
        formRef && formRef.$refs.dataformGroup.clearValidate()
      })
    },
    handleClose () {
      this.initFormData = {}
      this.resetForm()
      this.showForm = false
      this.$emit('update:drawer', false)
    },
    handleDialogClose () {
      this.showBillList = false
    },
    resetForm () {
      this.$set(this, 'formData', cloneDeep(this.initFormData))
    },
    validateUnpayBill (data) {
      return new Promise((resolve, reject) => {
        this.$get({
          url: `/bill/getCustomerUnpayBill?idType=${data.idType}&idNum=${data.idNum}`
        }).then(res => {
          if (!res) {
            reject(res)
            return
          }
          this.billList = res.data || []
          if (res.data) {
            const option = this.idTypeOptions.find(o => o.value === data.idType) || {}
            const idTypeName = option.label || ''
            this.dialogTip = `${idTypeName}-${data.idNum}`
            this.confirmData = data
            this.showBillList = true
            resolve(false)
          } else {
            resolve(true)
          }
        })
      })
    },
    addApi (formData) {
      return new Promise((resolve, reject) => {
        this.$post({
          url: '/customer/save',
          data: formData
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('新增成功!')
            this.handleClose()
            this.handleDialogClose()
            this.$emit('submitFinish')
          })
          .catch(reject)
      })
    },
    async handleAdd ({ formData }) {
      try {
        const pass = await this.validateUnpayBill(formData)
        if (pass) await this.addApi(formData)
        return true
      } catch (error) {
        return error
      }
    },
    handleEdit ({ formData }) {
      return new Promise((resolve, reject) => {
        this.$patch({
          url: `/customer/update/${formData.id}`,
          data: formData
        })
          .then(res => {
            if (!res) return
            resolve('success')
            this.$message.success('编辑成功!')
            this.handleClose()
            this.$emit('submitFinish')
          })
          .catch(reject)
      })
    },
    handleFormChange ({ field, value, values }) {
      if (field === 'idType') {
        values.idNum = ''
        this.updateFormRule(values)
      }
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>

<style lang="sass" scoped>
.form-box
  height: 500px
  .table-box
    height: calc( 100% - 38px )
</style>
