<template lang="pug">
el-tabs.full-box(
  v-model="activeTab"
  type="border-card")
  el-tab-pane.tab-item-box(
    name="base"
    label="基本信息")
    DataForm.base-form(
      ref="form"
      v-model="data"
      type="grid"
      :gridspanNum="3"
      gridFlow="row"
      :inlineGrid="true"
      readonly
      :formFields="baseFormFields")
  el-tab-pane.tab-item-box(
    name="settle"
    label="结算信息")
    DataForm.base-form(
      ref="form1"
      v-model="data"
      type="grid"
      :gridspanNum="3"
      gridFlow="row"
      :inlineGrid="true"
      readonly
      :formFields="settleFormFields")
  el-tab-pane.tab-item-box(
    name="usewater"
    label="关联用水户")
    DataTable.full-box(
      v-if="activeTab === 'usewater'"
      ref="table"
      :showFilter="false"
      :hasPage="false"
      resource="/usewater/list"
      :extraFilterBy="filterBy"
      :filterFields="filterFields"
      :columns="columns")
</template>

<script>
import keyBy from 'lodash/keyBy'

export default {
  name: 'DetailTab',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    groupSplit: {
      type: Array,
      default: () => ([])
    },
    formFields: {
      type: Array,
      default: () => ([])
    },
    columns: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    // 过滤字段的对象化，方便调取
    filterFieldsObject () {
      return keyBy(this.formFields, 'name')
    },
    filterBy () {
      return this.data.id ? { customerId: this.data.id } : {}
    },
    baseFormFields () {
      return this.groupSplit[0].map(name => this.filterFieldsObject[name])
    },
    settleFormFields () {
      return this.groupSplit[1].map(name => this.filterFieldsObject[name])
    }
  },
  data () {
    return {
      activeTab: 'base',
      filterFields: [{
        name: 'customerId',
        label: '用户id',
        showable: false,
        form: {
          tag: 'input'
        }
      }]
    }
  },
  methods: {}
}
</script>

<style scoped>
.tab-item-box {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.base-form {
  padding: 0 2px;
}
.full-box >>> .el-tabs__content {
  height: calc(100% - 39px);
}
</style>
