import cloneDeep from 'lodash/cloneDeep'
import regexs from '@/utils/regexs'

export const groupTitle = ['基本信息', '结算信息']

export const groupSplit = [
  ['customerCode', 'customerName', 'createTime', 'phone', 'address', 'idType', 'idNum', 'orgId', 'idAttchIds'],
  ['settlementName', 'settlementBank', 'settlementAccountNo', 'settlementPhone', 'invoiceName', 'invoiceBank', 'invoicePhone', 'invoiceTax', 'invoiceBankAccount']
]

export function getIdNumRule (idType) {
  const rule = [regexs.required]
  switch (idType) {
    case '0': // 身份证
      rule.push(regexs.idCard)
      break
    case '1': // 营业执照
      rule.push(regexs.bussinessCard)
      break
    default:
      break
  }
  return rule
}

export function getIdAttchRule (idType) {
  const rule = []
  switch (idType) {
    case '0': // 身份证
    case '1': // 营业执照
      rule.push(regexs.required)
      break
    default:
      break
  }
  return rule
}

export const editableFields = [
  {
    name: 'customerCode',
    label: '编号',
    form: {
      tag: 'input',
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'customerName',
    label: '姓名',
    form: {
      tag: 'input',
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'orgId',
    label: '水厂',
    form: {
      tag: 'select',
      filterable: true,
      relation: 'waterWorks',
      clearable: true,
      rules: [{ required: true, message: '必填' }]
    },
    render: { type: 'select' }
  },
  {
    name: 'createTime',
    label: '建档日期',
    form: {
      tag: 'date',
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'phone',
    label: '联系电话',
    form: {
      tag: 'input',
      rules: [{ required: true, message: '必填' }, regexs.mobileAndPhone]
    }
  },
  {
    name: 'address',
    label: '联系地址',
    form: {
      tag: 'input',
      colSpan: 2,
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'idType',
    label: '证件类型',
    form: {
      tag: 'select',
      relation: 'idType',
      rules: [{ required: true, message: '必填' }]
    },
    render: { type: 'select' }
  },
  {
    name: 'idNum',
    label: '证件号码',
    form: {
      tag: 'input',
      // colSpan: 2,
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'idAttchIds',
    label: '证件附件',
    form: {
      tag: 'file',
      rules: [{ required: true, message: '必填' }],
      typeLimit: ['jpg', 'jpeg', 'png'],
      colSpan: 3,
      resource: '/wfbase/upload',
      resType: 'CUSTOMER'
    },
    render: { type: 'file', showType: 'image' }
  },
  {
    name: 'settlementName',
    label: '姓名',
    form: {
      tag: 'input'
    }
  },
  {
    name: 'settlementBank',
    label: '银行',
    form: {
      tag: 'input'
    }
  },
  {
    name: 'settlementAccountNo',
    label: '账户',
    form: {
      tag: 'input'
    }
  },
  {
    name: 'settlementPhone',
    label: '联系电话',
    form: {
      tag: 'input',
      rules: [regexs.mobileAndPhone]
    }
  },
  {
    name: 'invoiceName',
    label: '开票名称',
    form: {
      tag: 'input'
    }
  },
  {
    name: 'invoiceBank',
    label: '开票银行',
    form: {
      tag: 'input'
    }
  },
  {
    name: 'invoicePhone',
    label: '开票联系电话',
    form: {
      tag: 'input',
      rules: [regexs.mobileAndPhone]
    }
  },
  {
    name: 'invoiceTax',
    label: '纳税人识别号',
    form: {
      tag: 'input'
    }
  },
  {
    name: 'invoiceBankAccount',
    label: '银行账号',
    form: {
      tag: 'input'
    }
  }
]

export const readonlyFields = editableFields.map(item => {
  const newItem = cloneDeep(item)
  newItem.form.tag = 'text'
  if (newItem.form.relation) {
    newItem.relation = newItem.form.relation
    delete newItem.form.relation
  }
  delete newItem.form.rules
  return newItem
})

export const tableColumns = [
  { name: 'enable', label: '启停状态', width: 100, relation: 'enable', render: { type: 'select' } },
  { name: 'customerCode', label: '编号', width: 120 },
  { name: 'customerName', label: '姓名', width: 120 },
  { name: 'address', label: '地址' },
  { name: 'idType', label: '证件类型', width: 100, relation: 'idType', render: { type: 'select' } },
  { name: 'idNum', label: '证件号码', width: 150 },
  { name: 'phone', label: '联系电话', width: 100 },
  { name: 'orgName', label: '水厂', width: 100 },
  { name: 'createTime', label: '建档时间', width: 120, render: { type: 'date' } }
]
